

























import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import GraphLegendIcon from '@/modules/common/components/ui-kit/graph-legend-icon.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import PROVIDER_COLORS from '@/modules/common/constants/providers.colors.constant';
import { BRAND_AS_BROKER_ANY } from '@/modules/cars/constants/car-filter-types.constant';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import { BROKER } from '../../constants/data-source-mode.constant';

export interface ICompanyLabel {
    name: string | null;
    color: string;
    disabled: boolean;
}

@Component({
    components: {
        GraphLegendIcon,
    },
})
export default class RatesGraphCarsLabel extends Vue {
    @Inject(CarsServiceS) private carsService!: CarsService;
    @Inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;

    @Prop({
        type: Array,
        default: () => [],
    })
    value!: string[];

    get carsGraphColor() {
        return this.carsService.carsGraphColor;
    }

    get currentCompany(): ICompanyLabel | null {
        const { currentCompany } = this.userService;

        if (!currentCompany) {
            return null;
        }

        return {
            name: currentCompany,
            color: CURRENT_HOTEL_GRAPH_COLOR,
            disabled: this.isCompetitorDisabled(currentCompany),
        };
    }

    get isBrokerToBroker() {
        const { isBrokerToBroker } = this.carsService;
        return isBrokerToBroker;
    }

    get competitors(): ICompanyLabel[] | null {
        const { isBrokerToBroker, brokersCompetitors } = this.carsService;
        const unselectedCompetitors = this.carsSharedService.getUnselectedCompetitors();
        const { competitorsFilter } = this.carsFiltersService;

        let competitorsList = [...competitorsFilter];
        if (isBrokerToBroker) {
            competitorsList = [...(brokersCompetitors || [])];
        }

        return this.carsGraphColor && Object.keys(this.carsGraphColor)
            .map(company => ({
                name: company,
                color: this.carsGraphColor[company],
                disabled: this.isCompetitorDisabled(company),
            }))
            .filter(item => competitorsList.includes(item.name))
            .filter(item => !unselectedCompetitors.includes(item.name) && this.carsFiltersService.isProviderAvailableInCurrentPOS(item.name));
    }

    get getIconColor() {
        return this.userService.currentCompany ? PROVIDER_COLORS[this.userService.currentCompany] : '#555555';
    }

    get currentBrand() {
        const { chainMode } = this.carsFiltersService;

        if (chainMode !== BROKER) {
            return '';
        }

        const { currentBrandAsBroker } = this.carsService;
        const [, brand] = currentBrandAsBroker.split(',');
        return (currentBrandAsBroker === BRAND_AS_BROKER_ANY) ? '(All)' : `(${brand})`;
    }

    isCompetitorDisabled(name: string) {
        return this.value.includes(name);
    }
    toggleCompetitor(name: string | null) {
        if (!name) {
            return;
        }
        let newVal = [...this.value];
        newVal = this.isCompetitorDisabled(name)
            ? newVal.filter((el: string) => el !== name)
            : [...newVal, name];
        this.$emit('input', newVal);
    }
    resetGraph() {
        this.$emit('input', []);
    }
}
